<template>
	<div>
		<v-textarea
			outlined
			height="500"
			class="mt-3"
			color="greenAccent2"
			background-color="white"
		></v-textarea>
	</div>
</template>

<script>
export default {
	name: "InteractionNetworkingSummary",
};
</script>
