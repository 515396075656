<template>
	<div>
		<component
			:is="current_step.component_name"
			:interview_id="interview_id"
		></component>

		<!-- Show finish dialog -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			current_step="app.interaction_and_networking.title"
			:dialog="dialog"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
import InterviewArrangements from "@/components/shared/tools/interview/Arrangements.vue";
import InteractSummary from "@/components/research/modules/reflectJourney/stages/interactAndNetw/steps/Summary.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";

export default {
	name: "InteractionsAndNetworkingPage",

	data() {
		return {
			interview_id: null,
			dialog: false,
		};
	},

	components: {
		InterviewArrangements,
		InteractSummary,
		StageFinishDialog,
	},

	computed: {
		...mapState({
			current_step: (state) => state.temporary.intr_netw_current_step,
			interviews: (state) => state.interview.list,
		}),
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getInterviews();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("in-finish", (payload) => {
				this.dialog = true;
			});
		},

		async getInterviews() {
			try {
				this.$loader.start();

				// Get list of interview
				await this.$store.dispatch("interview/list");

				// If list is empty then create a new one.
				if (!this.interviews || !this.interviews.length) {
					await this.createInterview();
				}

				// FIXME:: for now, only first interview has been taken.
				this.interview_id = this.interviews[0].id;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createInterview() {
			try {
				this.$loader.start();

				await this.$store.dispatch("interview/create", {
					interviewees: [{ name: null, role: null }],
					date: `${new Date().toISOString().substr(0, 10)}`,
					goal: null,
					questions: [
						{ title: "Question Title", value: null },
						{ title: "Question Title", value: null },
					],
					additional_details: [
						{
							title: "Comments",
							value: null,
						},
					],
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("in-finish");
	},
};
</script>
